<script lang="ts" setup>
import SkrButton from '@/components/button/SkrButton.vue'
import BaseDialog from '@/components/dialogs/BaseDialog.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'

defineProps<{
  loading: boolean
  businessName: string
}>()

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'action', data: Dialogs['business/DialogBusinessInvitation']['contextData']): void
}>()

const { t } = useI18n()

const accept = () => {
  emit('action', {})
}
</script>

<template>
  <base-dialog
    data-cy="business_invitation_dialog"
    max-width="640"
    persistent
    :title="t('gtc.scenario_5.title', { organisation: businessName })"
    @close="emit('close')"
  >
    <v-row class="py-10">
      <v-col cols="12" sm="7">
        <p class="mb-4">
          {{
            t('gtc.scenario_5.text', {
              organisation: businessName,
            })
          }}
        </p>
        <i18n-t tag="p" keypath="gtc.scenario_5.disclaimer" class="pb-8">
          <template #tou>
            <a :href="t('global.legal.terms_of_use_link')">{{ t('global.legal.terms_of_use') }}</a>
          </template>
          <template #privacyPolicy>
            <a :href="t('global.legal.privacy_policy_link')">{{ t('global.legal.privacy_policy') }}</a>
          </template>
        </i18n-t>
        <skr-button size="xl" block :loading="loading" @click="accept">
          {{ t('gtc.scenario_5.acknowledgement') }}
        </skr-button>
      </v-col>
      <v-col v-if="$vuetify.display.smAndUp" sm="5">
        <responsive-image source="gtc-dialog-business" :width="206" :height="128" />
      </v-col>
    </v-row>
    <template #actions>
      <v-spacer />
    </template>
  </base-dialog>
</template>
